import React from 'react'

import Layout from './components/_Layout'

const TermsPage = () => (
  <Layout path='terms'>
    <h2>Terms of service</h2>
    <h4>1. CONTRACTUAL RELATIONSHIP</h4>
    <p>
      These Terms and Conditions of Use (“Terms”) govern the access or use by
      you (“User” or “You”), an individual, acting upon the direction, consent,
      or endorsement of an employing principal (“Employer”) of applications,
      websites, content, products, and services (the “Services”) made available
      in the United States and its territories and possessions by StayCircles,
      LLC and its subsidiaries and affiliates (collectively, the “Company”),
      including, but not limited to, the web-based and mobile phone application,
      StayCircles (“StayCircles”).
    </p>
    <p>
      PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICES.
      By accessing the StayCircles application or its website found at
      staycircles.com, whether through a mobile device, mobile application, or
      computer you agree to be bound by these Terms, whether or not you create a
      StayCircles account. In these Terms, the words “including” and “include”
      mean “including, but not limited to.”
    </p>
    <p>
      Your access and use of the Services constitutes your agreement to be bound
      by these Terms, which establishes a contractual relationship between you
      and Company. If you do not agree to these Terms, you may not access or use
      the Services. These Terms expressly supersede prior agreements or
      arrangements with you. Company may immediately terminate these Terms or
      any Services with respect to you, or generally cease offering or deny
      access to the Services or any portion thereof, at any time for any reason.
    </p>
    <p>
      Supplemental terms may apply to certain Services, such as policies for a
      particular event, activity or promotion, and such supplemental terms will
      be disclosed to you in connection with the applicable Service(s).
      Supplemental terms are in addition to, and shall be deemed a part of, the
      Terms for the purposes of the applicable Service(s). Supplemental terms
      shall prevail over these Terms in the event of a conflict with respect to
      the applicable Services.
    </p>
    <p>
      Company may amend the Terms related to the Services from time to time.
      Amendments will be effective upon Company’s posting of such updated Terms
      at this location or the amended policies or supplemental terms on the
      applicable Service(s). Your continued access or use of the Services after
      such posting constitutes your consent to be bound by the Terms, as
      amended. Our collection and use of personal information in connection with
      the Services is as provided in Company’s Privacy Policy located below.
    </p>

    <h4>2. THE SERVICES</h4>
    <h5>A. General</h5>
    <p>
      The Services constitute a technology platform that enables users of
      Company’s mobile application(s) or website(s) (each, an “Application”) to
      connect themselves and their Employer with other users (“Third Party
      Providers”) to list, coordinate, and book short term lodging
      accommodations for the purposes of business travel. YOU ACKNOWLEDGE THAT
      COMPANY DOES NOT PROVIDE HOTEL, HOSPITALITY, LODGING, OR GENERAL TRAVEL
      SERVICES NOR DOES COMPANY WARRANT OR ASSURE THE SAME AS PROVIDED BY THIRD
      PARTY PROVIDERS. THE COMPANY DOES NOT WARRANT, MANAGE, OVERSEE, OR OPERATE
      IN ANY SUPERVISORY CAPACITY OVER THIRD-PARTY PROVIDER(S) AND ANY SERVICES
      WHICH MAY RESULT OR FOLLOW USER’S USE OF THE COMPANY’S SERVICES.
    </p>
    <h5>
      B. Retention of Intellectual Property Rights and Limited License for
      Personal Noncommercial Use
    </h5>
    <p>
      The Services and all associated original content, features, and
      functionality are owned by Company and are protected by international
      copyright, trademark, patent, trade secret, and other intellectual
      property or proprietary rights laws. Any rights not expressly granted
      herein are reserved by Company and Company’s licensors.
    </p>
    <p>
      Subject to your compliance with these Terms, Company grants you a limited,
      non-exclusive, non-sublicensable, revocable, non-transferrable license to:
      (i) access and use the Applications on your personal device solely in
      connection with your use of the Services; and (ii) access and use any
      content, information and related materials that may be made available
      through the Services, in each case solely for your personal, noncommercial
      use.
    </p>
    <h5>C. Restrictions</h5>
    <p>
      You may not: (i) remove any copyright, trademark or other proprietary
      notices from any portion of the Services; (ii) reproduce, modify, prepare
      derivative works based upon, distribute, license, lease, sell, resell,
      transfer, publicly display, publicly perform, transmit, stream, broadcast
      or otherwise exploit the Services except as expressly permitted by
      Company; (iii) decompile, reverse engineer or disassemble the Services
      except as may be permitted by applicable law; (iv) link to, mirror or
      frame any portion of the Services; (v) cause or launch any programs or
      scripts for the purpose of scraping, indexing, surveying, or otherwise
      data mining any portion of the Services or unduly burdening or hindering
      the operation and/or functionality of any aspect of the Services; or (vi)
      attempt to gain unauthorized access to or impair any aspect of the
      Services or its related systems or networks.
    </p>
    <h5>D. Third Part Services and Content</h5>
    <p>
      The Services may be made available or accessed in connection with third
      party services and content (including advertising) that Company does not
      control. You acknowledge that different terms of use and privacy policies
      may apply to your use of such third party services and content. Company
      does not endorse such third party services and content and in no event
      shall Company be responsible or liable for any products or services of
      such third party providers. Additionally, Apple Inc., Google, Inc.,
      Microsoft Corporation or BlackBerry Limited will be a third-party
      beneficiary to this contract if you access the Services using Applications
      developed for Apple iOS, Android, Microsoft Windows, or Blackberry-powered
      mobile devices, respectively. These third party beneficiaries are not
      parties to this contract and are not responsible for the provision or
      support of the Services in any manner. Your access to the Services using
      these devices is subject to terms set forth in the applicable third party
      beneficiary’s terms of service.
    </p>
    <h5>E. Ownership</h5>
    <p>
      The Services and all rights therein are and shall remain Company’s
      property or the property of Company’s licensors. Neither these Terms nor
      your use of the Services convey or grant to you any rights: (i) in or
      related to the Services except for the limited license granted above; or
      (ii) to use or reference in any manner Company’s company names, logos,
      product and service names, trademarks or services marks or those of
      Company’s licensors.
    </p>

    <h4>3. YOUR USE OF THE SERVICES</h4>
    <h5>A. User Accounts</h5>
    <p>
      In order to use most aspects of the Services, you must register for and
      maintain an active personal user Services account (“Account”). To obtain
      and maintain an Account, You must be at least 18 years of age or the
      minimum age allowed by applicable law (if the minimum allowable age is
      other than, and greater to, 18 years of age) for all jurisdictions you,
      your Employer, or your solicited Third Party Providers, will use the
      services (“Minimum Age Requirement”). By creating and maintaining an
      Account, User certifies that User is, at all times that User’s Account is
      active, compliant with the Minimum Age Requirement.
    </p>
    <p>
      By accessing and using the Services, You represent and warrant that you
      have the right, authority and capacity to enter into these Terms and abide
      by all terms and conditions of these Terms. Account registration requires
      You to submit to Company certain personal information, including, but not
      limited to, your name, address and other location information, information
      about your social relations, photographs of yourself and your property,
      mobile phone number and age, as well as at least one valid payment method
      (either a credit card or accepted payment partner). You agree to maintain
      accurate, complete, and up-to-date information in your Account. Your
      failure to maintain accurate, complete, and up-to-date Account
      information, including having an invalid or expired payment method on
      file, may result in your inability to access and use the Services or
      Company’s termination of this Agreement with you. You are responsible for
      all activity that occurs under your Account, and You agree to maintain the
      security and secrecy of your Account username and password at all times.
      Unless otherwise permitted by Company in writing, you may only possess one
      Account.
    </p>
    <h5>B. Termination</h5>
    <p>
      We may terminate your Account, access to the Services, without cause or
      notice, which may result in the forfeiture and destruction of all
      information associated with your account. All provisions of this Agreement
      that, by their nature, should survive termination shall survive
      termination, including, without limitation, ownership provisions, warranty
      disclaimers, indemnity, and limitations of liability.
    </p>
    <h5>C. Account Security</h5>
    <p>
      You are responsible for maintaining the confidentiality of your username
      and password, and are solely responsible for all activities that occur
      under your username and password. You agree to immediately notify the
      Company of any disclosure or unauthorized use of your username or password
      or any other breach of security and ensure that You log out from your
      account at the end of each session.
    </p>
    <h5>D. User Requirements and Conduct</h5>
    <p>
      The Service is not available for use by persons under the age of 18, or
      other minimum age of use as set forth by the laws of the local state,
      city, town, municipality where the Services are being requested and/or
      obtained. You may not authorize third parties to use your Account, and you
      may not allow persons under the age of 18, and/or who do not satisfy
      Minimum Age Requirement, to receive or utilize services of Third-Party
      Providers. You may not assign or otherwise transfer your Account to any
      other person or entity. You agree to comply with all applicable laws when
      using the Services, and you may only use the Services for lawful purposes
      (e.g., for lawful entertainment purposes only). You will not in your use
      of the Services cause nuisance, annoyance, inconvenience, physical damage,
      or property damage, whether to the Third Party Provider or any other
      party. You will not prior to, during, after, or in any way relative to
      your use of the Services, violate any laws, statutes, ordinances of the
      United States of America, or the State, City, Town, or Municipality of the
      Services are provided. In certain instances you may be asked to provide
      proof of identity to access or use the Services, and you agree that you
      may be denied access to or use of the Services if you refuse to provide
      proof of identity.
    </p>
    <h5>E. User Provided Content</h5>
    <p>
      Company may, in Company’s sole discretion, permit you from time to time to
      submit, upload, publish or otherwise make available to Company through the
      Services textual, audio, and/or visual content and information, including
      commentary and feedback related to the Services, initiation of support
      requests, and submission of entries for competitions and promotions (“User
      Content”). Any User Content provided by you remains your property.
      However, by providing User Content to Company, you grant Company a
      worldwide, perpetual, irrevocable, transferrable, royalty-free license,
      with the right to sublicense, to use, copy, modify, create derivative
      works of, distribute, publicly display, publicly perform, and otherwise
      exploit in any manner such User Content in all formats and distribution
      channels now known or hereafter devised (including in connection with the
      Services and Company’s business and on third-party sites and services),
      without further notice to or consent from you, and without the requirement
      of payment to you or any other person or entity.
    </p>
    <p>
      You represent and warrant that: (i) you either are the sole and exclusive
      owner of all User Content or you have all rights, licenses, consents and
      releases necessary to grant Company the license to the User Content as set
      forth above; and (ii) neither the User Content nor your submission,
      uploading, publishing or otherwise making available of such User Content
      nor Company’s use of the User Content as permitted herein will infringe,
      misappropriate or violate a third party’s intellectual property or
      proprietary rights, or rights of publicity or privacy, or result in the
      violation of any applicable law or regulation.
    </p>
    <p>
      You agree to not provide User Content that is defamatory, libelous,
      hateful, violent, obscene, pornographic, unlawful, or otherwise offensive,
      as determined by Company in its sole discretion, whether or not such
      material may be protected by law. Company may, but shall not be obligated
      to, review, monitor, or remove User Content, at Company’s sole discretion
      and at any time and for any reason, without notice to you.
    </p>
    <h5>F. Network Access and Devices</h5>
    <p>
      You are responsible for obtaining the data network access necessary to use
      the Services. Your mobile network’s data and messaging rates and fees may
      apply if you access or use the Services from a wireless-enabled device.
      You are responsible for acquiring and updating compatible hardware or
      devices necessary to access and use the Services and Applications and any
      updates thereto. Company does not guarantee that the Services, or any
      portion thereof, will function on any particular hardware or devices. In
      addition, the Services may be subject to malfunctions and delays inherent
      in the use of the Internet and electronic communications.
    </p>
    <h5>G. Cookies and Outside Websites</h5>
    <p>
      The Services use cookies. By using the Service, you consent to Company’s
      use of cookies in accordance with the terms of our Privacy Policy. The
      Services may contain links to other websites (the “Linked Sites”), which
      are not operated by Company. Company has no control over the Linked Sites
      and accepts no responsibility for them or for any loss or damage that may
      arise from your use of them. Your use of the Linked Sites will be subject
      to the terms of use and service contained within each such site.
    </p>

    <h4>4. REPRESENTATIONS, WARRANTIES, AND ASSUMPTION</h4>
    <p>
      YOU HEREBY COVENENT, REPRESENT, AND WARRANT THE FOLLOWING: (1) EMPLOYER IS
      YOUR ACTUAL EMPLOYER OR PRINCIPAL DIRECTING YOUR ACTIONS FOR PROFESSIONAL
      PURPOSES AND YOU ARE AN EMPLOYEE OR AGENT OF THE SAEM FOR PROFESSIONAL
      PURPOSES; (2) YOUR USE OF THE SERVICES IS UPON THE DIRECTION OF EMPLOYER
      AND WITH THEIR KNOWLEDGE AND CONSENT; (3) YOU WILL NOT FURNISH FALSE
      INFORMATION – OR ACT WITH NEGLIGENCE AS TO THE FALSITY OF INFORMATION
      FURNISHED TO COMPANY – ABOUT EMPLOYER, INCLUDING, WITHOUT LIMITATION,
      INDICIA OF EMPLOYER’S CONSENT THAT YOU USE THE SERVICES AT THEIR EXPENSES,
      FINANCIAL INFORMATION, OR PAYMENT INFORMATION; (4) IN CASE OF BREACH OF
      THESE TERMS, INCLUDING THE CASE OF EMPLOYER’S NON-PAYMENT FOR FINANCIAL
      OBLIGATIONS INCURRED IN USE OF THE SERVICES, YOU ASSUME FULL LIABILITY FOR
      EMPLOYER, INCLUDING, WITHOUT LIMITAITON, RESPONSIBILITY FOR PAYMENT OF ALL
      CHARGES; (5) NO OUTSTANDING POLICE OR LEGAL REGULATION, SANCTION, OR OTHER
      RESTRICTION WOULD PREVENT YOU FROM PERFORMING YOUR OBLIGATIONS UNDER THESE
      TERMS OR SERVICES; (6) USE OF THE SERVICES CONSTITUTES ACCEPTANCE OF A
      CONTRACTUAL OBLIGATION TO PERFORM ALL CONSENTED TO SERVICES ACCEPTED
      THEREIN.
    </p>

    <h4>5. PAYMENT</h4>
    <h5>A. Payment from You</h5>
    <p>
      You understand that use of the Services may result in charges or fees to
      You or your Employer for the services or goods you solicit or receive from
      a Third Party Provider (“Charges”). After you have received services or
      goods obtained through your use of the Services, Company will facilitate
      your, or your Employer’s, payment of the applicable Charges on behalf of
      the Third Party Provider, as such Third Party Provider’s limited payment
      collection agent. Payment of the Charges in such manner shall be
      considered the same as payment made directly by you or your Employer to
      the Third Party Provider. Charges will be inclusive of applicable taxes
      where required by law. Charges paid by you are final and non-refundable,
      unless otherwise determined by Company. You retain the right to request
      lower Charges from a Third Party Provider for services or goods received
      by you from such Third Party Provider at the time you receive such
      services or goods. Company will respond accordingly to any request from a
      Third Party Provider to modify the Charges for a particular service or
      good.
    </p>
    <p>
      All Charges are due immediately and payment will be facilitated by Company
      using the preferred payment method designated in your Account or the
      account of your Employer, after which Company will send You and your
      Employer a receipt by email. If you or your Employer’s primary Account
      payment method is determined to be expired, invalid or otherwise not able
      to be charged, you agree that Company may, as the Third Party Provider’s
      limited payment collection agent, use a secondary payment method in your
      Account, if available.
    </p>
    <p>
      As between you and Company, Company reserves the right to establish,
      remove and/or revise Charges for any or all services or goods obtained
      through the use of the Services at any time in Company’s sole discretion.
      Company will use reasonable efforts to inform you of Charges that may
      apply, provided that you will be responsible for Charges incurred under
      your Account regardless of your awareness of such Charges, your Employers
      obligation to pay, or the amounts thereof. Company may from time to time
      provide certain users with promotional offers and discounts that may
      result in different amounts charged for the same or similar services or
      goods obtained through the use of the Services, and you agree that such
      promotional offers and discounts, unless also made available to you, shall
      have no bearing on your use of the Services or the Charges applied to you.
      You may elect to cancel your request for services or goods from a Third
      Party Provider at any time prior to such Third Party Provider’s arrival,
      in which case you may be charged a cancellation fee.
    </p>
    <p>
      This payment structure is intended to fully compensate the Third Party
      Provider for the services or goods provided. Company does not designate
      any portion of your payment as a tip or gratuity to the Third Party
      Provider. Any representation by Company (on Company’s website, in the
      Application, or in Company’s marketing materials) to the effect that
      tipping is “voluntary,” “not required,” and/or “included” in the payments
      you make for services or goods provided is not intended to suggest that
      Company provides any additional amounts, beyond those described above, to
      the Third Party Provider. You understand and agree that, while you or your
      Employer are free to provide additional payment as a gratuity to any Third
      Party Provider who provides you with services or goods obtained through
      the Service, you are under no obligation to do so. Gratuities are
      voluntary. After you have received services or goods obtained through the
      Service, you will have the opportunity to rate your experience and leave
      additional feedback about your Third Party Provider.
    </p>
    <h5>B. Payment to You</h5>
    <p>
      You understand that, under certain circumstances, your use of the Services
      may result in the payment of a portion of the Charges to your Account. You
      hereby consent to Company’s payment of your proportion of the Charges
      through a credit charged to your account via any third party payment
      processing platform Company desires. Company makes no covenant,
      representation, or warranty as to the timeliness of such payments or the
      services of third party payment processing platform in distributing your
      Account fund to you.
    </p>

    <h4>6. DISCLAIMERS; LIMITATION OF LIABILITY; INDEMNITY.</h4>
    <h5>A. Disclaimer</h5>
    <p>
      THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” COMPANY DISCLAIMS
      ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, NOT
      EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN
      ADDITION, COMPANY MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE
      REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, OR
      AVAILABILITY OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH
      THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR
      ERROR-FREE. COMPANY DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR
      ABILITY OF THIRD PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING
      OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN
      CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT
      PERMITTED UNDER APPLICABLE LAW.
    </p>
    <h5>B. Limitation of Liability</h5>
    <p>
      COMPANY, ITS AFFILIATES OR ITS PARTNERS SHALL NOT BE LIABLE FOR INDIRECT,
      INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES,
      INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY, OR PROPERTY DAMAGE
      RELATED TO, IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE
      SERVICES, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
      DAMAGES. COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES
      ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON THE SERVICES OR YOUR
      INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR
      RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY PROVIDER, INCLUDING AGENTS
      AND MANAGEMENT OF THIRD-PARTY PROVIDERS, EVEN IF COMPANY HAS BEEN ADVISED
      OF THE POSSIBILITY OF SUCH DAMAGES. COMPANY SHALL NOT BE LIABLE FOR DELAY
      OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND COMPANY’S
      REASONABLE CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY PROVIDERS MAY OFFER
      LEGAL ADULT ENTERTAINMENT SERVICES AND MAY NOT BE PROFESSIONALLY LICENSED
      OR PERMITTED.
    </p>
    <p>
      COMPANY’S SERVICES MAY BE USED BY YOU TO REQUEST LEGAL ADULT ENTERTAINMENT
      SERVICES WITH THIRD PARTY PROVIDERS, BUT YOU AGREE THAT COMPANY HAS NO
      RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY LEGAL OR ILLEGAL ADULT
      ENTERTAINMENT SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN
      AS EXPRESSLY SET FORTH IN THESE TERMS.
    </p>
    <p>
      THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO LIMIT
      LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE EXCLUDED UNDER
      APPLICABLE LAW.
    </p>
    <h5>C. Indemnity</h5>
    <p>
      You agree to indemnify and hold Company and its officers, directors,
      employees, and agents harmless from any and all claims, demands, losses,
      liabilities, and expenses (including attorneys’ fees), arising out of or
      in connection with: (i) your use of the Services or services or goods
      obtained through your use of the Services; (ii) your breach or violation
      of any of these Terms; (iii) Company’s use of your User Content; or (iv)
      your violation of the rights of any third party, including Third Party
      Providers and their agents or management.
    </p>

    <h4>7. DISPUTE RESOLUTION</h4>
    <h5>A. Arbitration</h5>
    <p>
      You agree that any dispute, claim or controversy arising out of or
      relating to these Terms or the breach, termination, enforcement,
      interpretation or validity thereof or the use of the Services
      (collectively, “Disputes”) will be settled by binding arbitration between
      you and Company, to be administered by the American Arbitration
      Association (“AAA”). The foregoing notwithstanding, each party retains the
      right to bring an individual action in small claims court and the right to
      seek injunctive or other equitable relief in a court of competent
      jurisdiction to prevent the actual or threatened infringement,
      misappropriation or violation of a party’s copyrights, trademarks, trade
      secrets, patents or other intellectual property rights. You acknowledge
      and agree that you and Company are each waiving the right to a trial by
      jury or to participate as a plaintiff or class in any purported class
      action or representative proceeding. Further, unless both you and Company
      otherwise agree in writing, the arbitrator may not consolidate more than
      one person’s claims, and may not otherwise preside over any form of any
      class or representative proceeding. If this specific paragraph is held
      unenforceable, then the entirety of this “Dispute Resolution” section will
      be deemed void. Except as provided in the preceding sentence, this
      “Dispute Resolution” section will survive any termination of these Terms.
    </p>
    <h5>B. Fees</h5>
    <p>
      The party initiating arbitration is responsible for paying any AAA filing,
      administrative and arbitrator fees will be solely as set forth in the AAA
      Rules.
    </p>
  </Layout>
)

export default TermsPage
